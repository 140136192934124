import React from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { ArrowLinkButton, Flex } from '../../../ui';
import { useAuthStore } from '../../auth/shared/store/useAuthStore';
import { SolidContentWrap } from '../../shared/components';
import { useTranslation } from '../../shared/i18n/translate/useTranslation';
import { SIGN_UP_DIALOG_HASH } from '../../auth/sign-up/ui/SignUpDialog';
import goldTierBadge from '../../../../assets/loyalty/gold-tier.svg?reference';
import { getGradientBackground } from '../../shared/styles';
import { FONT_FAMILY_AVENIR_NEXT_LT_PRO } from '../../shared/styles/Fonts';
import Responsive from '../../../ui/utils/Responsive';

export const GuestBanner = observer(() => {
	const { t } = useTranslation();
	const { isAuthenticated } = useAuthStore();
	const isTablet = Responsive.useMatchesBreakpoint('tablet');

	if (isAuthenticated) {
		return null;
	}

	return (
		<StyledSolidContentWrap>
			<StyledFlex>
				<StyledTitleActionContainer>
					<StyledTitleContainer>
						<h2>{t('dashboard_guest_banner_title')}</h2>
					</StyledTitleContainer>
					<StyledFlex>
						<StyledItemContainer>
							<StyledArrowLinkButton inverted href={SIGN_UP_DIALOG_HASH}>
								{t('user_create_account_title')}
							</StyledArrowLinkButton>
						</StyledItemContainer>
						{isTablet && (
							<StyledItemContainer>
								<StyledImage src={goldTierBadge} alt="" role="presentation" />
							</StyledItemContainer>
						)}
					</StyledFlex>
				</StyledTitleActionContainer>

				{!isTablet && (
					<StyledItemContainer>
						<StyledImage src={goldTierBadge} alt="" role="presentation" />
					</StyledItemContainer>
				)}
			</StyledFlex>
		</StyledSolidContentWrap>
	);
});

const StyledSolidContentWrap = styled(SolidContentWrap)`
	height: 205px;
	border-radius: 12px;
	h2 {
		font-size: 32px;
		color: #fff;
		line-height: 40px;
		margin: 0;
	}

	${getGradientBackground('purple-blue')}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		height: fit-content;
		padding: 16px;
		margin: 12px 16px;

		h2 {
			line-height: 26px;
			font-size: 22px;
		}
	}
`;

const StyledTitleActionContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;
	@media (${Responsive.getMediaQueryForBreakpoint('tabletXL')}) {
		gap: 10px;
	}
`;

const StyledFlex = styled(Flex)`
	height: 100%;
	gap: 16px;
	flex-direction: row;
	align-content: space-between;
	align-items: center;
	justify-content: space-between;

	@media (${Responsive.getMediaQueryForBreakpoint('tabletXL')}) {
		gap: 12px;
	}
`;

const StyledTitleContainer = styled.div`
	flex-grow: unset;
	justify-content: center;
`;
const StyledItemContainer = styled.div`
	height: fit-content;
	display: flex;
`;

const StyledArrowLinkButton = styled(ArrowLinkButton)`
	font-family: ${FONT_FAMILY_AVENIR_NEXT_LT_PRO};
	padding: 0 15px;
	@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
		padding: 0 12px;
	}
`;

const StyledImage = styled.img`
	width: 115px;
	height: 130px;
	@media (${Responsive.getMediaQueryForBreakpoint('tabletXL')}) {
		width: 57px;
		height: 65px;
	}
`;
